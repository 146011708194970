import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Tools = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.tools" })} />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "tool list" })}
        </h3>
        <div className="mx-auto mb-6 w-full">
          <Link to="/tools/featured-image-generator">
            <div className="bg-white relative rounded block md:flex border border-gray-400 min-h-12rem hover:shadow">
              <div className="relative w-full md:w-2/5 h-full overflow-hidden rounded-t md:rounded-t-none md:rounded-l min-h-12rem">
                <img
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src="/images/tools/eye-catch-maker.svg"
                  alt=""
                ></img>
              </div>
              <div className="w-full md:w-3/5 h-full rounded">
                <div className="p-3 md:p-6">
                  <p className="text-lg md:text-xl font-bold mb-3">
                    {intl.formatMessage({ id: "tools.featuredImageGen" })}
                  </p>
                  <p className="text-sm md:text-base">
                    {intl.formatMessage({ id: "tools.featuredImageGenDesc" })}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </section>
    </Layout>
  )
}
export default Tools
